.alert {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    max-width: 90%;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    z-index: 1000;
    animation: fadein 0.3s, fadeout 0.3s 4.7s;
}

@keyframes fadein {
    from { opacity: 0; top: 0; }
    to { opacity: 1; top: 10px; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}

.alert.success { background-color: #108315; } /* Green */
.alert.error { background-color: #851710; } /* Red */

.close-btn {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}
